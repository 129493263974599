var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Drawer",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        size: "small",
        "z-index": 2,
      },
      on: { close: _vm.onClose, onSubmit: _vm.onsubmit },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("UserInfo", { attrs: { "user-info": _vm.userInfo } }),
          _c(
            "div",
            { staticClass: "insurance" },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "停保", prop: "type" } },
                    [
                      _c(
                        "a-checkbox-group",
                        {
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        [
                          _vm.socialStatus === 1
                            ? _c(
                                "a-checkbox",
                                { attrs: { value: "social", name: "社保" } },
                                [_vm._v("社保")]
                              )
                            : _vm._e(),
                          _vm.medicalStatus === 1
                            ? _c(
                                "a-checkbox",
                                { attrs: { value: "medical", name: "医保" } },
                                [_vm._v("医保")]
                              )
                            : _vm._e(),
                          _vm.housingStatus === 1
                            ? _c(
                                "a-checkbox",
                                { attrs: { value: "housing", name: "公积金" } },
                                [_vm._v("公积金")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "setForm" },
            [
              _vm.form.type.includes("social")
                ? _c("AddFormVue", {
                    ref: "social",
                    attrs: {
                      title: "社保",
                      "insure-classify": "social",
                      "is-flag": false,
                      "disabled-list": _vm.disabledList,
                      "form-data": _vm.socialData,
                      "stop-insure": "",
                    },
                  })
                : _vm._e(),
              _vm.form.type.includes("medical")
                ? _c("AddFormVue", {
                    ref: "medical",
                    attrs: {
                      title: "医保",
                      "insure-classify": "medical",
                      "is-flag": false,
                      "disabled-list": _vm.disabledList,
                      "form-data": _vm.medicalData,
                      "stop-insure": "",
                    },
                  })
                : _vm._e(),
              _vm.form.type.includes("housing")
                ? _c("AddFormVue", {
                    ref: "housing",
                    attrs: {
                      title: "公积金",
                      "insure-classify": "housing",
                      "is-flag": false,
                      "disabled-list": _vm.disabledList,
                      "form-data": _vm.housingData,
                      "stop-insure": "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }